.comments-container-with-chat {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
}

.comments-no-margin {
margin: 0 !important;
}

.comments-chat {
  padding: 0 0 5px 0;
  border: solid 1px #d6d5d6;
  width: 500px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 2px 2px #aaa;
  background-color: rgb(240 237 240);
  color: #846b8f;
}

.comments-chat-header {
  height: 30px;
  color: white;
  background-color: #ababab;
  display: flex;
  flex-flow: column;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0 10px;
  border-radius: 0 0 5px 5px;
}

.comments-chat-messages {
  display: flex;
  flex-direction: column;
  height: 80%;
  background: rgb(240 237 240);
  border-radius: 18px;
  padding: 10px;
  overflow-y: scroll;
}

.comments-chat-messages, .comments-chat-messages li {
  color:rgb(132, 107, 143);
}

.comments-chat-footer {
  position: relative;
  right: 0;
  bottom: 0px;
  left: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-around;
  padding: 12px;
}

.comments-chatInput {
  width: calc(100% - 70px);
  font-size: 18px;
  color: white;
  border: 1px solid #542b66;
  font-weight: 100;
  padding: 10px;
  border-radius: 5px;
  background: #8c5fa0;
}

.comments-chatInput::placeholder{
  color: rgb(182, 182, 182) !important;
}

.comments-chat-toggle-logo {
  transform: rotate(90deg);
  height: 15px;
  position: relative;
  inset: 6px 2px;
}

.comments-chat-send-logo {
  position: relative;
  right: 5px;
  height: 35px;
  bottom: -7px;
  z-index: 10;
  cursor: pointer;
}

.chat-comments-msg{
  flex: 1 1;
  padding-top: 15px;
  height: auto;
  overflow-y: clip;
}

.chat-comments-chatbox { 
  height: auto;
  max-width: 100%;
  display: flex;
  flex-flow: column;
  border-radius: 5px;
  padding: 12px 15px 30px 15px !important;
  overflow-y: scroll;
}

.chat-comments-container  {
  background-color: rgb(240, 237, 240);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 2px 4px #a7a7a7;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.chat-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  color: gray;
  font-size: 16px;
}

.chat-list-container {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  transition: left 0.3s ease;
}

.chat-container-mine{
  display: flex;
  justify-content: flex-end;
  animation: fadeInMenu ease 0.3s;
}  

.chat-container-other{
  display: flex;
  justify-content: flex-start;
  animation: fadeInMenuOther ease 0.3s;
}

.chat-mine-comments{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  word-wrap: break-word;
  background-color: white;
  border-radius: 5px;
  padding: 10px 15px;
  word-break: break-word;
  width: 98%;
  max-width: 100%;
  position: relative;
  color: #3e3e3e;
}

.chat-mine-comments-arrow:after, .chat-mine-comments-arrow:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.chat-mine-comments-arrow:after {
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #3e3e3e;
  border-width: 7px;
  margin-top: -7px;
}

.chat-arrow:after, .chat-arrow:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.chat-arrow:after {
  border-color: rgba(136, 183, 213, 0);
  border-right-color: lightgray;
  border-width: 7px;
  margin-top: -7px;
}
