.activeAccountPage{
  height: 100vh;
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.05);
}

.rowCentered {
  flex: auto;
  justify-content: center;
  margin: 0;
  box-sizing: border-box;
  margin-right: auto !important;
  margin-left: auto !important;
}

.boxActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infoBox {
  padding: 0 5rem 0 5rem;
}

.breadcrumb {
  width:5rem;
  background-color: #fff;
}

.titleText {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
}

.centeredText {
  text-align: center;
  font-size: 1rem;
  padding: 1rem 4rem 1rem 4rem;
}

.normalText {
  text-align: justify;
  font-size: 1rem;
  padding: 0 1rem 0 1rem;
}

.checkText {
  font-size: 0.8rem;
  padding: 0 2.5rem 0 2.5rem;
}

.imageStyle {
  width: 35rem;
  margin-bottom: 15px;
}


@media only screen and (max-width: 420px) {
  .activeAccountPage {
    background-color: rgba(0,0,0,0.05);
    background-image: none;
  }
}

/* Define an animation behavior */
@keyframes spinner {
  to { transform: rotate(360deg); }
}

.faSpinner {
  /* Apply 'spinner' keyframes looping once every second (1s)  */
  animation: spinner 1s linear infinite;
}