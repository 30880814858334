.react-player {
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}
.react-player video:focus {
  outline: none;
}

.modal-background {
    background-color: #491b5c;
}
.modal-container{
    padding: 0px !important
}
.custom-map-modal .modal-dialog {
    width: 100VW;
    height: 100VH;
    margin: 0;
}

.modal-body{
    padding: 0px !important;
    min-height: 100% !important;
    min-width: 100%;
}

.custom-map-modal .modal-content {
    height: auto;
    width: 100VW;
    height: 100VH;
    border-radius: 0;
}

.body-content {
}

.body-media-control {
    display: flex;
    align-items: center;
    justify-content: center;
}

.body-media-control .icon-back {
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.body-media-control .icon-play {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.body-media-control .icon-next {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.body-media {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.body-range {
  position: relative;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.range {
    width: 50%;
}

.body-font {
  line-height: 24px;
}
  
.body-rating {
  color: #000;
  font-size: 3vw;
  display: flex;
  align-items: center;
}

.body-description {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}

@media(min-width:700px){
    .body-description {
        margin: 15px 0;
    }

    .body-range {
        position: relative;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
      }

      .body-content.modal-body{
        width: 80%px;
        min-width: 0;
      }

      .body-range > .rhap_container {
          width: 80% !important;
      }

      .modal-content{
          align-items: center;
      }
}

input[type=range] {
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: #7b5a89;
    border-radius: 25px;
    border: 0px solid #000101;
}

input[type=range]::-webkit-slider-thumb {
    border: 0px solid #000000;
    height: 25px;
    width: 25px;
    background: #FFF;
    border-radius: 50%;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -10px;
}

.body-time{
    color: #FFF
}

.modal-backdrop.show {
    opacity: 0 !important;
    background-color: #7b5a89;
}

@keyframes modalFade {
    from {transform: translateX(100%);opacity: 0;}
    to {transform: translateX(0);opacity: 1;}
  }

.modal.fade:not(.show).right .modal-dialog {
	-webkit-transform: translate3d(125%, 0, 0);
	-ms-transform: translate3d(125%, 0, 0);
	-o-transform: translate3d(125%, 0, 0);
	transform: translate3d(125%, 0, 0);
}