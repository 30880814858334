.cardline {
    overflow: hidden;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  
  .cardline:hover {
    -webkit-filter: brightness(60%);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  .title-front-card {
    font-family: Arial;
    text-align: right;
  }
  
  .cardline .title-front-card {
    background: rgba(255, 255, 255, 0);
    position: absolute;
    bottom: 0;
    color: #000;
    right: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 30px;
    transition: .3s;
    font-size: 26px;
    color: white;
  }
  
  @media only screen and (max-width: 768px) {
    .title-front-card {
      text-align: right;
    }
    .cardline .title-front-card {
      font-size: 16px;
    }
    .row .cardline {
      height: 184px;
    }
  }
  
  @media only screen and (min-width: 769px) {
    .cardline .title-front-card {
      width: 100%;
    }
    .row .cardline {
      height: 45vh;
      min-height: 275px;
    }
  }
  
  .ytplaylist-text{
    width: 100%;
    font-weight: 600;
  }
  
  .ytplaylist {
    background-color: white;
    padding: 0;
  }
  
  .ytplaylist-img-container {
    width: 100%;
    padding-top: 80%;
    position: relative;
  }
  
  .ytplaylist-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .ytplayer {
    background-color: white;
  }
  
  .ytplaylist-content {
    border-radius: 2px;
    overflow: hidden;
    padding: 0px !important;
    margin: 10px auto 20px;
    width: 80%;
    box-shadow: 0 1px 4px #aaa;
    background: #fff;
  }
  .ytplayList-box-shadow{
    -webkit-box-shadow: -1px 7px 27px 0px rgba(230, 230, 230, 0.75);
    -moz-box-shadow: -1px 7px 27px 0px rgba(230, 230, 230, 0.75);
    box-shadow: -1px 7px 27px 0px rgba(230, 230, 230, 0.75);
    border-radius: 1%;
  }
  .ytplaylist-data {
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    flex-direction: column;
    flex-wrap: wrap;
    height: 180px;
    justify-content: space-between;
  }
  .ytplaylist-image {
    margin: 4%;
    border-radius: 2%;
  }
  .ytplaylist-progress{
    width: 40%;
    align-self: flex-start;
    display:flex;
    background-color:black;
    width:30%;
    align-self: left;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    flex: 0 1;
  }
  
  .ytplaylist-title-sub {
    color: inherit;
    margin-bottom: 5px;
  }
  .ytplaylist-subtitle {
    margin-bottom: 5px;
    margin-bottom: 5px;
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    flex: 0 1;
  }
  
  .ytplaylist-title {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 16px;
    text-align: center;
    background-color: transparent;
  }
  
  .ytplaylist-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .ytplaylist-description{
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 14px;
    flex: 1 1;
  }
  .ytplaylis-concluido{
      font-weight: bold;
      font-style: normal;
      font-family: Open Sans;
      font-size: 13px;
      line-height: 14px;
      color: #1DB954;
      margin-top: 2.5%;
      margin-bottom: 2.5%;
  }
  @media(min-width: 800px) {
    .ytplaylist-body {
      min-height: 100px;
    }
  }
  
  .ytplaylist-icon-play {
    display: none;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -100%);
  }
  
  .ytplayer-title {
    color: #4b4057;
    text-align: left;
  }
  
  .ytplayer-barra {
    border-top: 1px solid #4b4057;
    width: 10%;
    float: left
  }
  
  .live-container {
    min-height: calc(100vh - 54px - 000px);
    margin-bottom: 000px;
  }
  
  .ytplayer-container {
    padding: 3% 10% 5% 10%;
  }
  
  .ytplayer-container.live {
    margin: 0 25%;
    padding: 0;
    background-color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 2px 2px #aaa;
  }
  
  @media only screen and (max-width: 1350px) {
    .ytplayer-container.live {
      margin: 0 20%;
    }
  }
  
  @media only screen and (max-width: 1200px) {
    .ytplayer-container.live {
      margin: 0 15%;
    }
  }
  
  @media only screen and (max-width: 900px) {
    .ytplayer-container.live {
      margin: 0;
    }
  }
  
  .ytplayer-container.live .icon-player {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%) !important;
    cursor: pointer;
  }
  
  .ytplayer-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ytplayer-container, .ytplayer-container {
    height: 100%;
  }
  
  .disabled {
    cursor: not-allowed;
  }
  
  .disabled:active {
    pointer-events: none;
  }
  
  .noContent{
    margin: 0 auto !important;
  }