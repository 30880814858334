.input-label{
    width: 100%;
}
.input{
    background: #fff;
    width: 100%;
    border: solid 1px #ccc !important;

}

.react-datepicker-wrapper,
.inputDateTime{
    width: 100%;
    display: block;
}

.input-w-50{
    width: calc(50% - 10px);
    display: inline-block;
    margin-top: 10px;
}
.input-w-50:first-child{
    margin-right: 20px;
}
.removerEvento{
    margin-left: 15px;
}


@media (max-width: 768px){
    .rbc-addons-dnd-resizable{
        padding: 5px;
        .rbc-addons-dnd-resize-ew-anchor,
        .rbc-event-content{
            display: none;
        }
    }

}