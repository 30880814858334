.chat-room-list {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 25px;
    border-radius: 10px;
    padding-top: 1.5rem;
    font-size: 20px;
    background-color: rgba(255,255,255,0.1);
    height: calc(100% - 160px);
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}