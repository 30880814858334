.onboard__icon-close {
  width: 50px;
  z-index: 1050;
  cursor: pointer;
  position: fixed;
  right: 20px;
  top: 20px;
}

.onboard__iframe {
  width: 100vw;
  height: 100vh;
  z-index: 1040;
  position: fixed;
  top: 0;
  left: 0;
}