.form-check-label {
    padding-left: 5px;
    margin-top: 3px;
}

.form-check {
    display: flex;
    align-items: center;
}

.terms-page-container {
    display: flex;
    justify-content: center;
}

.terms-page-item {
    margin-top: 15px;
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 5px 15px;
    cursor: pointer;
    position: relative;
    box-shadow: 1px 1px 5px lightgrey;
}

.terms-page-item:hover:after{
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: inherit;
    background-color: rgba(0,0,0,0.05);
    transition: background-color ease 1s;
  }

.terms-page-item:not(:last-child) {
    margin-right: 15px;
}

.terms-checkbox-container > .form-group {
    margin-bottom: 5px;
}

.terms-form-group {
    display: flex;
    align-items: center;
}

.terms-check-number {
    margin-left: 3px;
    color: gray;
    margin-top: 3px;
}