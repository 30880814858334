.rating{
   
    max-width: 385px;
    display: inline-block;
    float: right;
    padding: 8px 0px;
    .rate-container{
        background: none !important;
        padding: 8px 0px;
    }
}


.progressBarContainer{
    display: inline-block;
    max-width: 40%;
    width: 40%;
}

.progressBarContainerFirst{
    display: block;
    max-width: 100%;
    width: 100%;
}

.ratingFirst{
    float: none;
    max-width: 100%;
    display: block;
    width: 100%;
}


.upload-list__item--link{
    .title{
        text-align: left;
    }
}

@media (max-width: 768px){

}

.upload-list__item {
    height: auto !important;
}
.upload-list__item:first-child {
    min-height: 360px !important;
    height: auto !important;
    border-top: 0;
    grid-column: span 2;
}
.rating.ratingFirst{
    margin-left: 0 !important;
}