.card-points{
    margin-top: 10px;
    width: 50%;
}
.section{
    display: flex;
    flex-wrap: wrap;
    padding-left: 2%;
    padding-right: 2%;
    align-items: center;
} 
.column{
    flex-direction: column !important;
    margin-top: 10%;
     margin-bottom: 0;
}
.second-section{
    margin-top: 4rem;
}
@media(max-width: 768px){
    .column{        
        margin-top: 4%;
    }
    .card-points{
        margin-top: 10px;
        width: 100%;
    }
    .second-section{
        margin-top: 4px;
    }
}